<template>
	<screen
		class="steps-view"
		size="large"
		style="background: transparent; box-shadow: none"
	>
		<div
			:style="{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative'
			}"
		>
			<h1>Settings a few things up... one moment please</h1>
			<loader
				:loading="true"
				:style="{ position: 'inherit', height: '55px' }"
				size="big"
			/>
		</div>
	</screen>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { mapActions } from 'vuex'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import Loader from '../../ui/Loader'
import Screen from '../../ui/Screen'
import Card from '../../ui/Card'
import InstitutionView from '../Institution/InstitutionView.vue'
import UiButton from '../../ui/Button'
import Collapsible from '../../ui/Collapsible'

export default {
	name: 'steps-view',
	components: {
		Screen,
		UiButton,
		UiLabel,
		Collapsible,
		Datepicker,
		Card,
		InstitutionView,
		UiInput,
		Loader
	},
	data() {
		return {}
	},
	methods: {
		...mapActions(['getMerchantDetails'])
	},
	mounted() {
		this.getMerchantDetails().then(merchant => {
			if (!merchant.institution) {
				this.$router.push({ name: 'select-cause' })
				return
			}
			if (sessionStorage.getItem('bc_ru_token') && !merchant.embed_id) {
				this.$router.push({ name: 'integrations.bigcommerce' })
				return
			}
			if (!merchant.stripe_source && !merchant.payment_verified) {
				this.$router.push({ name: 'step-verify-deposits' })
				return
			}
			if (!merchant.stripe_source) {
				this.$router.push({ name: 'select-bank' })
				return
			}
			this.$router.push({ name: 'dashboard' })
		})
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.steps-view {
	.roundup-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.institutions-list {
		margin-top: 10px;
		max-height: 500px;
		overflow-y: scroll;
		li {
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			padding: 15px 0;
			display: flex;
			text-align: left;
			list-style: none;
			cursor: pointer;
			&:hover {
				background: $roundup-darker-blue;
			}
			img {
				width: 40px;
				height: 40px;
				border-radius: 8px;
			}
			.item-text {
				padding-left: 15px;
				span {
					display: block;
					color: $white;
					&.item-subtitle {
						opacity: 0.5;
						font-size: 13px;
					}
				}
			}
		}
	}
	.institutions-search {
		position: relative;
		.loading {
			padding-right: 55px;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 0px;
				height: 0px;
				border-radius: 50%;
				right: 15px;
				top: 25px;
				border: 2px solid rgba(255, 255, 255, 0.8);
				border-right: 2px solid $roundup-navy;
				animation: rotate360 0.5s infinite linear,
					exist 0.1s forwards ease;
			}
		}
	}
}
</style>
